import "../App.css";
import "./CaptionImage.css";

function CaptionImage(props) {
  return (
    <div className="captionImage">
      <img src={props.src} style={{ width: "auto" }} />
      <p>{props.caption}</p>
    </div>
  );
}

export default CaptionImage;
