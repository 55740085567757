import "./Coaches.css";
import "../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import CaptionImage from "../modules/CaptionImage";
import { Carousel } from "react-responsive-carousel";
import CoachList from "./CoachList";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import { useState } from "react";

const CoachView = ({ coach }) => {
  return (
    <div className="coachView">
      <h2>{coach.name}</h2>
      <h4>{coach.title}</h4>
      {coach.images.length > 0 ? (
        <CaptionImage
          src={coach.images[0].src}
          caption={coach.images[0].caption}
        />
      ) : null}
      <p dangerouslySetInnerHTML={{ __html: coach.paragraphs[0] }} />
      {coach.images.length > 1 ? (
        <CaptionImage
          src={coach.images[1].src}
          caption={coach.images[1].caption}
        />
      ) : null}
      {coach.paragraphs.length > 1
        ? coach.paragraphs.map((p, index) => {
            if (index > 0) {
              return <p dangerouslySetInnerHTML={{ __html: p }} />;
            } else {
              return null;
            }
          })
        : null}
    </div>
  );
};

const CoachButton = ({ index, coach, slide, setSlide }) => {
  return (
    <button
      class="coachButton"
      onClick={() => setSlide(index)}
      style={
        slide == index ? { color: "white", backgroundColor: "#232e63" } : {}
      }
    >
      <p>{coach.name.split(" ")[0]}</p>
      {coach.title}
    </button>
  );
};

function Coaches() {
  const [slide, setSlide] = useState(0);
  return (
    <div>
      <Header active={"Coaches"} />
      <div className="content">
        <div class="buttonGroup">
          <h5>Head Coaches</h5>
          <div>
            {CoachList.filter((a) => a.type == "Head").map((coach, index) => {
              return (
                <CoachButton
                  key={index}
                  index={index}
                  coach={coach}
                  slide={slide}
                  setSlide={setSlide}
                />
              );
            })}
          </div>
          <h5>Assistant Coaches</h5>
          <div>
            {CoachList.filter((a) => a.type == "Assistant").map(
              (coach, index) => {
                return (
                  <CoachButton
                    key={index}
                    index={
                      index + CoachList.filter((a) => a.type == "Head").length
                    }
                    coach={coach}
                    slide={slide}
                    setSlide={setSlide}
                  />
                );
              }
            )}
          </div>
        </div>
        <div style={{ width: "95vw", maxWidth: "60ch", margin: "auto" }}>
          <Carousel
            showThumbs={false}
            showArrows={false}
            showIndicators={false}
            infiniteLoop={true}
            selectedItem={slide}
            swipeScrollTolerance={50}
            preventMovementUntilSwipeScrollTolerance={true}
            onChange={(result) => {
              if (result != slide) {
                setSlide(result);
              }
            }}
          >
            {CoachList.filter((a) => a.type == "Head").map((coach, index) => {
              return <CoachView key={index} index={index} coach={coach} />;
            })}
            {CoachList.filter((a) => a.type == "Assistant").map(
              (coach, index) => {
                return <CoachView key={index} index={index} coach={coach} />;
              }
            )}
          </Carousel>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Coaches;
