import "../App.css";
import "./Records.css";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { RecordList } from "./RecordInterfaces.tsx";
import { useState } from "react";

const RecordCard = ({ gender, entries }) => {
  return (
    <div className="card">
      <h5 className="cardtitle">{gender}</h5>
      <div className="recordEntry">
        <div className="recordAmount">
          <h2>{entries[0].amount}</h2>
        </div>
        <div style={{ flex: 1 }}>
          {entries.map((entry) => {
            return (
              <div className="recordData">
                <div
                  className="recordPhoto"
                  style={
                    entry.image !== undefined
                      ? { backgroundImage: `url(${entry.image})` }
                      : {}
                  }
                />
                <div className="recordName">
                  <h3>{entry.name}</h3>

                  {entry.dates.map((date) => {
                    return <h5>{date}</h5>;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Category = ({ stat, category }) => {
  const genders = ["Boys", "Girls"];
  return (
    <div>
      <h2>{category}</h2>
      {genders.map((gender) => {
        return (
          <RecordCard
            entries={RecordList[stat][category][gender]}
            gender={gender}
          />
        );
      })}
    </div>
  );
};

const StatButton = ({ title, setStat, stat }) => {
  return (
    <button
      class="coachButton"
      onClick={() => setStat(title)}
      style={
        stat == title ? { color: "white", backgroundColor: "#232e63" } : {}
      }
    >
      <p>{title}</p>
    </button>
  );
};

const statOptions = ["Kills", "Aces", "Digs", "Blocks", "Assists"];
const categories = ["set", "match", "season", "career"];

function Records() {
  const [stat, setStat] = useState("Kills");
  return (
    <div>
      <Header active={"Records"} />
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          {statOptions.map((statOption) => {
            return (
              <StatButton title={statOption} setStat={setStat} stat={stat} />
            );
          })}
        </div>
        {categories.map((category) => {
          return <Category stat={stat} category={category} />;
        })}

        {/* <h2>Kills</h2>
        <table>
          <th colSpan={6}>Per Set</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Cole Revis</td>
            <td>6.0</td>
            <td>3/4/2023 vs Francis Parker (CA) (1 set)</td>
            <td>Lauren Rumel</td>
            <td>9.6</td>
            <td>10/15/2020 vs Casa Grande (5 Sets)</td>
          </tr>
          <tr>
            <td>Cole Revis</td>
            <td>6.0</td>
            <td>3/21/2023 @ Mountain View (3 sets)</td>
          </tr>
          <tr>
            <td>Cole Revis</td>
            <td>6.0</td>
            <td>4/7/2023 vs ALA Anthem (1 set)</td>
          </tr>

          <th colSpan={6}>Match</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Tolu Obafemi</td>
            <td>27</td>
            <td>3/22/2012 vs Ironwood Ridge</td>
            <td>Lauren Rumel</td>
            <td>48</td>
            <td>10/15/2020 vs Casa Grande</td>
          </tr>
          <tr>
            <td>Andrew Adair</td>
            <td>27</td>
            <td>4/24/2014 @ Ironwood Ridge</td>
          </tr>
          <th colSpan={6}>Season</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Jacob Martinez</td>
            <td>301</td>
            <td>2022</td>
            <td>Lauren Rumel</td>
            <td>628</td>
            <td>2022</td>
          </tr>
          <th colSpan={6}>Career</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Cole Revis</td>
            <td>535</td>
            <td>Class of 2023</td>
            <td>Lauren Rumel</td>
            <td>1,237</td>
            <td>Class of 2022</td>
          </tr>
        </table> */}
        {/*
        <h2>Aces</h2>
        <table>
          <th colSpan={6}>Per Set</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Wilson Spaulding</td>
            <td>4</td>
            <td>4/7/2023 @ Desert View (3 Sets)</td>
            <td>Olivia Rubio</td>
            <td>5</td>
            <td>9/6/2019 vs Cholla (1 Set)</td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td>Sophie Cline</td>
            <td>5</td>
            <td>9/7/2019 vs Pueblo (1 Set)</td>
          </tr>

          <th colSpan={6}>Match</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Wilson Spaulding</td>
            <td>12</td>
            <td>4/7/2023 @ Desert View</td>
            <td>Michelle Snyder</td>
            <td>11</td>
            <td>9/22/2009 @ Desert View</td>
          </tr>

          <th colSpan={6}>Season</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Kyle Boesen</td>
            <td>54</td>
            <td>2022</td>
            <td>Ella Cartwright</td>
            <td>68</td>
            <td>2022</td>
          </tr>

          <th colSpan={6}>Career</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Kyle Boesen</td>
            <td>84</td>
            <td>Class of 2023</td>
            <td>Ella Cartwright</td>
            <td>148</td>
            <td>Class of 2023</td>
          </tr>
        </table>

        <h2>Blocks</h2>
        <table>
          <th colSpan={6}>Per Set</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Liam Giffin</td>
            <td>2.6</td>
            <td>4/28/2015 vs CDO (5 Sets)</td>
            <td>Nikki Johnson</td>
            <td>3.0</td>
            <td>10/10/2016 @ Mt. View (2 Sets)</td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td>Zoe Wung</td>
            <td>3.0</td>
            <td>10/8/2022 @ Marana (1 Set)</td>
          </tr>

          <th colSpan={6}>Match</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Liam Giffin</td>
            <td>13</td>
            <td>4/28/2015 vs CDO</td>
            <td>Kelsey Siemons</td>
            <td>7</td>
            <td>9/9/2016 @ RUHS</td>
          </tr>
          <tr>
            <td colSpan={3}></td>
            <td>Lauren Rumel</td>
            <td>7</td>
            <td>10/18/2018 vs Sahuaro</td>
          </tr>

          <th colSpan={6}>Season</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Cole Revis</td>
            <td>44</td>
            <td>2022</td>
            <td>Jiana Lawson</td>
            <td>59</td>
            <td>2020</td>
          </tr>

          <th colSpan={6}>Career</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Cole Revis</td>
            <td>80</td>
            <td>Class of 2023</td>
            <td>Lauren Rumel</td>
            <td>140</td>
            <td>Class of 2022</td>
          </tr>
        </table>

        <h2>Digs</h2>
        <table>
          <th colSpan={6}>Per Set</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Johvan Fiallos</td>
            <td>9.3</td>
            <td>4/19/2022 vs Mt View (3 Sets)</td>
            <td>Margo Courtney</td>
            <td>9.3</td>
            <td>10/13/2015 vs Salpointe (3 Sets)</td>
          </tr>

          <th colSpan={6}>Match</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Johvan Fiallos</td>
            <td>28</td>
            <td>4/19/2022 vs Mt View</td>
            <td>Ella Parsons</td>
            <td>40</td>
            <td>9/12/2019 @ RUHS</td>
          </tr>

          <th colSpan={6}>Season</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Johvan Fiallos</td>
            <td>278</td>
            <td>2022</td>
            <td>Ella Parsons</td>
            <td>388</td>
            <td>2021</td>
          </tr>

          <th colSpan={6}>Career</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Kyle Boesen</td>
            <td>445</td>
            <td>Class of 2022</td>
            <td>Ella Parsons</td>
            <td>781</td>
            <td>2021</td>
          </tr>
        </table>

        <h2>Assists</h2>
        <table>
          <th colSpan={6}>Per Set</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Kyle Boesen</td>
            <td>13</td>
            <td>5/6/2022 vs Centennial (3 Sets)</td>
            <td>Ava Pritz</td>
            <td>12.6</td>
            <td>9/14/2021 vs Cienega (5 Sets)</td>
          </tr>

          <th colSpan={6}>Match</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Matt Klewer</td>
            <td>60</td>
            <td>4/24/2013 vs Sahuaro</td>
            <td>Ava Pritz</td>
            <td>63</td>
            <td>9/14/2021 vs Cienega</td>
          </tr>

          <th colSpan={6}>Season</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Kyle Boesen</td>
            <td>567</td>
            <td>2022</td>
            <td>Ava Pritz</td>
            <td>931</td>
            <td>2021</td>
          </tr>

          <th colSpan={6}>Career</th>
          <tr>
            <td colSpan={3}>
              <u>Boys</u>
            </td>
            <td colSpan={3}>
              <u>Girls</u>
            </td>
          </tr>
          <tr>
            <td>Kyle Boesen</td>
            <td>665</td>
            <td>Class of 2023</td>
            <td>Ava Pritz</td>
            <td>1104</td>
            <td>Class of 2022</td>
          </tr>
        </table> */}
        <h6></h6>
      </div>
      <Footer />
    </div>
  );
}

export default Records;
