import "../App.css";
import "./award.css";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import lancer23 from "../imgs/awards/2023lancer.jpg";
import lancer24 from "../imgs/awards/2024lancer.png";
import realm23 from "../imgs/awards/2023realm.jpg";
import tusd22 from "../imgs/awards/2022tusd.jpg";

function Awards() {
  return (
    <div>
      <Header active={"Awards"} />
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* 2024 Boys Awards */}
        <div className="card award">
          <h2>2024 Boy's Awards</h2>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>Region</td>
                <td>Southern AZ</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Jacob Martinez (OH)</td>
                <td>🥇 1st Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Dylan Rubio (OPP)</td>
                <td>🥇 1st Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Zane Schwartz (L)</td>
                <td>🥈 2nd Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Wilson Spaulding (M)</td>
                <td>🥈 2nd Team</td>
                <td>🥈 2nd Team</td>
              </tr>
              <tr>
                <td>Logan Freas (OH)</td>
                <td></td>
                <td>🥈 2nd Team</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* 2024 Lancer Champs */}
        <div className="card award">
          <h2>2024 Lancer Invite Champions</h2>
          <img src={lancer24} />
          <p>
            The boys went <strong>6-0</strong> on their way to a championship at
            the 2024 Lancer Invitational tournament. Foothills defeated Cienega
            in the championship match, 25-21 25-19. Dylan Rubio (Opp), Jacob
            Martinez (OH), and Zane Schwartz (L) were named 1st team all
            tournament.{" "}
            <a href="https://azpreps365.com/articles/17193-catalina-foothills-wins-the-lancer-invitational">
              News Article
            </a>{" "}
            -{" "}
            <a href="https://facebook.com/media/set/?set=a.943842017747530&type=3&ref=embed_post&wtsid=rdr_0JRWmULMC1mk0NHMc">
              Photos
            </a>
          </p>
        </div>
        {/* 2023 Girls Awards */}
        <div className="card award">
          <h2>2023 Girl's Awards</h2>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>Region</td>
                <td>Southern AZ</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Julia Ford (L)</td>
                <td>🏆 Defensive Player of the Year</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Molly Brunell (OH)</td>
                <td>🥇 1st Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Cassie Leary (MB)</td>
                <td>🥇 1st Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Lauren Glasner (S)</td>
                <td>🥇 1st Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Tori Staples (MB)</td>
                <td>🥈 2nd Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Mercy Martinez (OH)</td>
                <td>🥈 2nd Team</td>
                <td>🥈 2nd Team</td>
              </tr>
              <tr>
                <td>Fiona Rucker (OPP)</td>
                <td>🥈 2nd Team</td>
                <td>🥈 2nd Team</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* 2023 ACP Champs */}
        <div className="card award">
          <h2>2023 Battle of the Realm Champions</h2>
          <img src={realm23} />
          <p>
            The girls <strong>didn't drop a set</strong> on bracket day to win a
            championship at the 2023 Battle of the Realm Invitational
            tournament. After losing a tight match at the end of pool play,
            Foothills got revenge on host Arizona College Prep in the
            championship match, 25-19 25-21. Molly Brunell brought home
            tournament MVP.{" "}
            <a href="https://www.instagram.com/p/CyZtTfkLI_C/?igsh=M2NqazFqcHBmaW1x">
              Instagram Post
            </a>
          </p>
        </div>
        {/* 2023 Boys Awards */}
        <div className="card award">
          <h2>2023 Boy's Awards</h2>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>Region</td>
                <td>Southern AZ</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cole Revis (OPP)</td>
                <td>🥇 1st Team</td>
                <td>🏆 Player of the Year</td>
              </tr>
              <tr>
                <td>Kyle Boesen (L)</td>
                <td>🥈 2nd Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Jacob Martinez (OH)</td>
                <td>🥈 2nd Team</td>
                <td>🥉 3rd Team</td>
              </tr>
              <tr>
                <td>London Dyjak (S)</td>
                <td>🥈 2nd Team</td>
                <td></td>
              </tr>
              <tr>
                <td>Tyler Deconcini (OH)</td>
                <td>Honorable Mention</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <p>Coach Andrew named 5A Southern AZ Coach of the Year</p>
        </div>
        {/* 2023 Lancer Champs */}
        <div className="card award">
          <h2>2023 Lancer Invite Champions</h2>
          <img src={lancer23} />
          <p>
            The boys went <strong>5-0</strong> on their way to a championship at
            the 2023 Lancer Invitational tournament. Foothills defeated Mountain
            Ridge in the championship match, 25-18 29-31 15-13. Kyle Senkerik
            led the Falcons with 16 assists, London Dyjack added 13 assists.
            Tyler DeConcini had 12 kills and Cole Revis had 8. Kyle Boesen (L)
            and Cole Revis (OPP) were named 1st team all tournament.{" "}
            <a href="https://azpreps365.com/articles/15827-catalina-foothills-survives-epic-match-with-mountain-ridge-to-win-the-lancer-invitational">
              News Article
            </a>{" "}
            -{" "}
            <a href="https://andymorales.smugmug.com/Sports/2023-boys-volleyball-lancer-invitational">
              Photos
            </a>
          </p>
        </div>
        {/* 2022 Girls Awards */}
        <div className="card award">
          <h2>2022 Girl's Awards</h2>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>Region</td>
                <td>Southern AZ</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Molly Brunell (OH)</td>
                <td>🥇 1st Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Ella Cartwright (L)</td>
                <td>🥇 1st Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Mercy Martinez (OH)</td>
                <td>🥈 2nd Team</td>
                <td>🥇 1st Team</td>
              </tr>
              <tr>
                <td>Brooklyn Palmer (OPP)</td>
                <td>🥈 2nd Team</td>
                <td>🥈 2nd Team</td>
              </tr>
              <tr>
                <td>Tori Staples (MB)</td>
                <td>🥈 2nd Team</td>
                <td>🥈 2nd Team</td>
              </tr>
              <tr>
                <td>Andrea Soroa (S)</td>
                <td>🥈 2nd Team</td>
                <td>🥈 2nd Team</td>
              </tr>
              <tr>
                <td>Julia Ford (L)</td>
                <td>Honorable Mention</td>
                <td>🥈 2nd Team</td>
              </tr>
              <tr>
                <td>Ana Haubner (MB)</td>
                <td>Honorable Mention</td>
                <td>🥉 3rd Team</td>
              </tr>
            </tbody>
          </table>
          <p>
            Brooklyn Palmer named all freshman team, 5A freshman of the year
          </p>
        </div>
        {/* 2022 Boys Awards */}
        <div className="card award">
          <h2>2022 Boy's Awards</h2>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>Region</td>
                <td>Southern AZ</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cole Revis (OPP)</td>
                <td>🥇 1st Team</td>
                <td>🥈 2nd Team</td>
              </tr>
              <tr>
                <td>Jacob Martinez (OH)</td>
                <td>🥇 1st Team</td>
                <td>🥈 2nd Team</td>
              </tr>
              <tr>
                <td>Kyle Boesen (S)</td>
                <td>🥈 2nd Team</td>
                <td>Honorable Mention</td>
              </tr>
              <tr>
                <td>Johvan Fiallos(L)</td>
                <td>🥈 2nd Team</td>
                <td>Honorable Mention</td>
              </tr>
              <tr>
                <td>Tyler Deconcini (OH)</td>
                <td>🥈 2nd Team</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <p>Jacob Martinez named Southern AZ Freshman of the Year</p>
        </div>
        {/* 2022 TUSD Champs */}
        <div className="card award">
          <h2>2022 TUSD Champions</h2>
          <img src={tusd22} />
          <p>
            The boys <strong>didn't drop a single SET</strong> on their way to a
            championship at the 2022 TUSD tournament. Foothills defeated Marana
            in the championship match, 25-15 25-23. Kyle Boesen (S) and Cole
            Revis (OPP) were named 1st team all tournament.{" "}
            <a href="https://azpreps365.com/articles/14262-catalina-foothills-wins-tusd-tournament-over-marana">
              News Article
            </a>{" "}
            -{" "}
            <a href="https://andymorales.smugmug.com/Sports/2022-TUSD-Boys-Volleyball-Tournament">
              Photos
            </a>
          </p>
        </div>
        <h6>
          This page was started after Coach Andrew took over in 2022. Awards
          before that can be found through azpreps365.com
        </h6>
      </div>
      <Footer />
    </div>
  );
}

export default Awards;
