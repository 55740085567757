import andrew from "../imgs/coaches/andrew.jpg";
import andrew2 from "../imgs/coaches/andrew2.jpg";
import ayda1 from "../imgs/coaches/ayda.JPG";
import ayda2 from "../imgs/coaches/ayda2.png";
import franklin1 from "../imgs/coaches/franklin.JPG";
import jules1 from "../imgs/coaches/jules.JPG";
import jules2 from "../imgs/coaches/jules1.png";
import keifer1 from "../imgs/coaches/keifer.JPG";
import kyle1 from "../imgs/coaches/kyle1.jpg";
import kyle2 from "../imgs/coaches/kyle2.jpeg";
import sam1 from "../imgs/coaches/sam1.png";
import sam2 from "../imgs/coaches/sam2.png";

const CoachList = [
  // Andrew
  {
    name: "Andrew Adair",
    title: "Varsity",
    type: "Head",
    images: [
      {
        src: andrew,
        caption: "Coach Andrew with the 2023 Boy's team.",
      },
      {
        src: andrew2,
        caption: "Coach Andrew's Senior Year at Catalina Foothills",
      },
    ],
    paragraphs: [
      `Coach Andrew Adair took over the Catalina Foothills program in the spring of 2022, eight
      years after graduating with the class of 2014. Alongside Coach Kyle, Andrew founded and
      directs <a href="http://sidewinderva.com" target="_blank" rel="noreferrer">Sidewinder
      Volleyball Academy</a>, a volleyball club for girls and boys in Tucson. In recent years,
      Andrew has coached the boys' 18-1, 17-1, and 15-1 teams at Sidewinder, as well as the
      University of Arizona Women’s Club team. In 2024, he coached the Arizona Region U17 High
      Performance team at the USA Volleyball All Star tournament.`,
      `Before joining Catalina Foothills, Andrew spent four years coaching the JV-A and JV-B
      teams at Rincon/University High School. Outside of coaching, Andrew works as a software
      engineer and founded <a href="http://stattogether.com" target="_blank" rel="noreferrer">Stat
      Together</a>, a mobile app for high school and club volleyball teams to manage stats and
      live stream matches to fans.`,
      `Andrew played four years of collegiate volleyball as an Outside Hitter at the University of
      Arizona, serving as team captain his senior year. He led his team to the Final Four and
      earned 1st Team All-Tournament honors. During his high school years at Catalina Foothills,
      he reached the State Tournament all four years and played in the State Championship game in
      2011, finishing as the state runner-up. Volleyball runs in Andrew's family—his step-dad, Brian,
      is the current <a href="http://beach.foothillsvolleyball.com" target="_blank" rel="noreferrer">Foothills
      Beach</a> coach, and his younger sisters play at Foothills and Orange Grove.`,
    ],
  },
  // Jules
  {
    name: "Julissa Serna",
    title: "JV-A",
    type: "Head",
    images: [
      {
        src: jules1,
        caption: "2023 Senior Night",
      },
      {
        src: jules2,
        caption: "As a player, Jules was part of Club Cactus",
      },
    ],
    paragraphs: [
      `This is Coach Julissa's second year coaching at Catalina Foothills High School for both the girl's
      and boy's programs.  She’s coached at both Orange Grove and Esperero Canyon Middle school as the
      head 6th, 7th and 8th grade coach.  This past year, she coached 2 seperate teams at Club Cactus
      Juniors and also for the U of A Dave Rubio summer volleyball camps.`,
      `Julissa was a 4 year volleyball player at Flowing Wells High School before she graduated in 2020.
      While in highschool, Julissa also played for Club Cactus Junior Volleyball Club.`,
    ],
  },
  // Franklin
  {
    name: "Franklin Donley",
    title: "JV-B",
    type: "Head",
    images: [
      {
        src: franklin1,
        caption:
          "Franklin coached the 15-2 team at Sidewinder Volleyball Academy",
      },
    ],
    paragraphs: [
      `Franklin has been a staple in the Tucson volleyball community. He has coached in Arizona since
      2006, working at Academy of Tucson, Catalina, and Tucson High Schools for girls and Salpointe
      Catholic for boys. On the club side, he has coach boys and girls club for Redington, RUSH, and
      Arizona Summit. Most recently, Franklin took his 18-1 team to a bronze finish at Nationals in
      2023. Before coming to Arizona, Franklin coached High School Boys and Club in Southern California.`,
      `Franklin knew he wanted to coach because of how lucky he was to receive great coaching when he was
      learning the game. Franklin says, "I want to pass that knowledge down and help players grow and
      succeed. Not just as athletes but as people, too".`,
      `Franklin played high school, club, and JUCO in Southern California.`,
    ],
  },
  // Kyle
  {
    name: "Kyle Wells",
    title: "Varsity",
    type: "Assistant",
    images: [
      {
        src: kyle1,
        caption:
          "Coach Kyle is the head of development at Sidewinder Volleyball Academy",
      },
      {
        src: kyle2,
        caption:
          "Coach Kyle (right) after winning the 2022 Boy's TUSD Tournament",
      },
    ],
    paragraphs: [
      `Kyle started at Catalina Foothills in 2020 as the boy's JV-A coach and in 2022 as the girl's JV-A coach.
    Kyle and Coach Andrew are the founders and directors of
    <a href="http://sidewinderva.com" target="_blank" rel="noreferrer">Sidewinder Volleyball Academy</a>, a girls and boys volleyball
    club in Tucson.  Kyle's true love is grass volleyball, so he founded
    <a href="https://sites.google.com/view/the-grass-valley-vb/home" target="_blank" rel="noreferrer">
    The Grass Valley Volleyball VB</a> where he runs competitive grass volleyball tournaments
    throughout the state of Arizona.`,
      `Coach Kyle played 4 years of collegiate volleyball as an Outside
    Hitter at the University of Arizona. During his 4 years, Kyle served as the President for 2 seasons
    and helped the team to the Elite 8 of the NCVF National Tournament. Before coming to Arizona, he played at Warren Township High School in
Illinois, and was named to the All-State team his senior year. Kyle also played for
Adversity Volleyball Club where his team finished in the top 10 for two years in a row,
in the open division of USAV Nationals.`,
    ],
  },
  // Ayda
  {
    name: "Ayda Felix",
    title: "Varsity",
    type: "Assistant",
    images: [
      {
        src: ayda1,
        caption: "Ayda coached the 16-1 team at Sidewinder Volleyball Academy",
      },
      {
        src: ayda2,
        caption: "Ayda played collegiately for Fresno Pacific",
      },
    ],
    paragraphs: [
      `Aydalis played for the national club level for three years. She played volleyball for Rincon/University
      High School and was on varsity for three years. During the 2019-2021 seasons,  she played for Pima College,
      was named the ACCAC 2021 Libero of the year, and the team earned a national bid in 2021. In 2022, she played
      for Fresno Pacific.`,
      ` Aydalis said this about being a coach: “I’m a coach because I want to make sure athletes that love the game
      have an opportunity to continue to grow to their potential. I want them to feel like they have the support
      and encouragement.”`,
    ],
  },
  // Keifer
  {
    name: "Keifer Neerhof",
    title: "JV-A",
    type: "Assistant",
    images: [
      {
        src: keifer1,
        caption:
          "Keifer coached the 17-1 team at Sidewinder Volleyball Academy",
      },
    ],
    paragraphs: [
      `With over 10 years of immersive playing experience at the collegiate, club and high school level, Keifer
      Neerhof is bringing energetic passion for volleyball and transitioning seamlessly into a coaching role.
      Equipped with an understanding of the game's nuances and strategies through years of competitive play at the
      high school level at Canyon Del Oro, club with APV and then collegiatly with the University of Arizona Men’s
      Volleyball. Keifer is translating his on-court expertise into effective coaching methodologies to elevate
      team performance. He is committed to instilling a strong work ethic, communication, strategic thinking, and
      a passion for the sport in aspiring athletes. He is excited to bring his personal insights and perspectives
      to cultivate a culture of excellence and achievement within the team he coaches.`,
    ],
  },
  // Sam
  {
    name: "Sam Gillman",
    title: "JV-B",
    type: "Assistant",
    images: [
      {
        src: sam1,
        caption: "Sam Gillman at the University of Arizona",
      },
      {
        src: sam2,
        caption: "2022 NCVF National Champions",
      },
    ],
    paragraphs: [
      `Coach Sam Gillman is a recent graduate from the University of Arizona.
        At the U of A, Sam played 4 years of Collegiate Club Volleyball as a Libero.
        During his 4 years, he led his team to a National Championship in Men’s Division
        III his Senior year. Sam also won the U of A 2022 Outstanding Sportsperson of
        the Year Award his Senior year for his loyalty, dedication, and hard work to the
        U of A Men’s Club Volleyball Team.`,
      `Before starting college at Arizona, Sam attended and played volleyball for New
        Trier Township High School in Illinois. Sam also played for Vortex Volleyball Club,
        where he played 4 years as an Outside Hitter and 2 years as a Libero.`,
    ],
  },
];

export default CoachList;

//   name: "Tanner Giessuebel",
//   title: "JV-B (Boys)",
//   imageOne: {
//     src: tanner,
//     caption: "Coach Tanner and his sister",
//   },
//   paragraphOne: `Coach Tanner Giessuebel graduated in 2014 from Catalina and returned
// in 2016 to coach at the JV and the Varsity level. He coached for three
// seasons with the boys volleyball program, and two seasons with the
// girls program. Tanner played setter for Catalina and helped lead them
// to a state tournament appearance in 2013 and 2014. Tanner also played
// one year of club volleyball with Tucson United.`,
//   imageTwo: {
//     src: tannerteam,
//     caption: "Coach Tanner (12) after winning the TUSD tournament in 2013",
//   },
//   paragraphTwo: `After taking time off of coaching to focus on school, he is excited to
// bring his knowledge and love of the game to the Catalina Foothills
// program for the 2022 season. Outside of coaching, Tanner works as a
// sales floor associate at Walgreens as he continues to pursue his
// education.`,
//   inactive: true,
// },
