import boesen from "../imgs/records/boesen.png";
import cartwright from "../imgs/records/cartwright.png";
import cline from "../imgs/records/cline.png";
import courtney from "../imgs/records/courtney.jpg";
import drubio from "../imgs/records/drubio.png";
import fiallos from "../imgs/records/fiallos.png";
import giffin from "../imgs/records/giffin.png";
import johnson from "../imgs/records/johnson.png";
import lawson from "../imgs/records/lawson.png";
import martinez from "../imgs/records/martinez.png";
import parsons from "../imgs/records/parsons.png";
import pritz from "../imgs/records/pritz.png";
import revis from "../imgs/records/revis.png";
import rubio from "../imgs/records/rubio.png";
import rumel from "../imgs/records/rumel.png";
import schwartz from "../imgs/records/schwartz.png";
import senkerik from "../imgs/records/senkerik.png";
import siemons from "../imgs/records/siemons.png";
import snyder from "../imgs/records/snyder.png";
import spaulding from "../imgs/records/spaulding.png";
import wung from "../imgs/records/wung.png";

interface RecordEntry {
  name: string;
  amount: number;
  dates: string[];
  image?: object;
}

interface RecordCategory {
  set: { Girls: RecordEntry[]; Boys: RecordEntry[] };
  match: { Girls: RecordEntry[]; Boys: RecordEntry[] };
  season: { Girls: RecordEntry[]; Boys: RecordEntry[] };
  career: { Girls: RecordEntry[]; Boys: RecordEntry[] };
}

interface Records {
  Kills: RecordCategory;
  Aces: RecordCategory;
  Digs: RecordCategory;
  Blocks: RecordCategory;
  Assists: RecordCategory;
}

export const RecordList: Records = {
  Kills: {
    set: {
      Boys: [
        {
          name: "Jacob Martinez",
          amount: 10,
          dates: ["4/30/2024 vs Independence (3 sets)"],
          image: martinez,
        },
      ],
      Girls: [
        {
          name: "Lauren Rumel",
          amount: 9.6,
          dates: ["10/15/2020 vs Casa Grande (5 Sets)"],
          image: rumel,
        },
      ],
    },
    match: {
      Boys: [
        {
          name: "Jacob Martinez",
          amount: 32,
          dates: ["4/2/2024 @ Buena"],
          image: martinez,
        },
      ],
      Girls: [
        {
          name: "Lauren Rumel",
          amount: 48,
          dates: ["10/15/2020 vs Casa Grande"],
          image: rumel,
        },
      ],
    },
    season: {
      Boys: [
        {
          name: "Jacob Martinez",
          amount: 395,
          dates: ["2024"],
          image: martinez,
        },
      ],
      Girls: [
        {
          name: "Lauren Rumel",
          amount: 628,
          dates: ["2020"],
          image: rumel,
        },
      ],
    },
    career: {
      Boys: [
        {
          name: "Jacob Martinez",
          amount: 852,
          dates: ["Class of 2025"],
          image: martinez,
        },
      ],
      Girls: [
        {
          name: "Lauren Rumel",
          amount: 1237,
          dates: ["Class of 2022"],
          image: rumel,
        },
      ],
    },
  },
  Aces: {
    set: {
      Boys: [
        {
          name: "Wilson Spaulding",
          amount: 4.0,
          dates: ["4/7/2023 @ Desert View (3 Sets)"],
          image: spaulding,
        },
      ],
      Girls: [
        {
          name: "Olivia Rubio",
          amount: 5.0,
          dates: ["9/6/2019 vs Cholla (1 Set)"],
          image: rubio,
        },
        {
          name: "Sophie Cline",
          amount: 5.0,
          dates: ["9/7/2019 vs Pueblo (1 Set)"],
          image: cline,
        },
      ],
    },
    match: {
      Boys: [
        {
          name: "Wilson Spaulding",
          amount: 12,
          dates: ["4/7/2023 @ Desert View"],
          image: spaulding,
        },
      ],
      Girls: [
        {
          name: "Michelle Snyder",
          amount: 11,
          dates: ["9/22/2009 @ Desert View"],
          image: snyder,
        },
      ],
    },
    season: {
      Boys: [
        {
          name: "Kyle Boesen",
          amount: 54,
          dates: ["2022"],
          image: boesen,
        },
      ],
      Girls: [
        {
          name: "Ella Cartwright",
          amount: 68,
          dates: ["2022"],
          image: cartwright,
        },
      ],
    },
    career: {
      Boys: [
        {
          name: "Kyle Boesen",
          amount: 84,
          dates: ["Class of 2023"],
          image: boesen,
        },
      ],
      Girls: [
        {
          name: "Ella Cartwright",
          amount: 148,
          dates: ["Class of 2023"],
          image: cartwright,
        },
      ],
    },
  },
  Blocks: {
    set: {
      Boys: [
        {
          name: "Liam Giffin",
          amount: 2.6,
          dates: ["4/28/2015 vs CDO (5 Sets)"],
          image: giffin,
        },
      ],
      Girls: [
        {
          name: "Nikki Johnson",
          amount: 3.0,
          dates: ["10/10/2016 @ Mt. View (2 Sets)"],
          image: johnson,
        },
        {
          name: "Zoe Wung",
          amount: 3.0,
          dates: ["10/8/2022 @ Marana (1 Set)"],
          image: wung,
        },
      ],
    },
    match: {
      Boys: [
        {
          name: "Liam Giffin",
          amount: 13,
          dates: ["4/28/2015 vs CDO"],
          image: giffin,
        },
      ],
      Girls: [
        {
          name: "Kelsey Siemons",
          amount: 7,
          dates: ["9/9/2016 @ RUHS"],
          image: siemons,
        },
        {
          name: "Lauren Rumel",
          amount: 7,
          dates: ["10/18/2018 vs Sahuaro"],
          image: rumel,
        },
      ],
    },
    season: {
      Boys: [
        {
          name: "Dylan Rubio",
          amount: 46,
          dates: ["2024"],
          image: drubio,
        },
      ],
      Girls: [
        {
          name: "Jiana Lawson",
          amount: 59,
          dates: ["2020"],
          image: lawson,
        },
      ],
    },
    career: {
      Boys: [
        {
          name: "Cole Revis",
          amount: 80,
          dates: ["Class of 2023"],
          image: revis,
        },
      ],
      Girls: [
        {
          name: "Lauren Rumel",
          amount: 140,
          dates: ["Class of 2022"],
          image: rumel,
        },
      ],
    },
  },
  Digs: {
    set: {
      Boys: [
        {
          name: "Johvan Fiallos",
          amount: 9.3,
          dates: ["4/19/2022 vs Mt View (3 Sets)"],
          image: fiallos,
        },
      ],
      Girls: [
        {
          name: "Margo Courtney",
          amount: 9.3,
          dates: ["10/13/2015 vs Salpointe (3 Sets)"],
          image: courtney,
        },
      ],
    },
    match: {
      Boys: [
        {
          name: "Zane Schwartz",
          amount: 29,
          dates: ["3/21/2024 vs Cienega"],
          image: schwartz,
        },
      ],
      Girls: [
        {
          name: "Ella Parsons",
          amount: 40,
          dates: ["9/12/2019 @ RUHS"],
          image: parsons,
        },
      ],
    },
    season: {
      Boys: [
        {
          name: "Zane Schwartz",
          amount: 326,
          dates: ["2024"],
          image: schwartz,
        },
      ],
      Girls: [
        {
          name: "Ella Parsons",
          amount: 388,
          dates: ["2021"],
          image: parsons,
          image: parsons,
        },
      ],
    },
    career: {
      Boys: [
        {
          name: "Kyle Boesen",
          amount: 445,
          dates: ["Class of 2023"],
          image: boesen,
        },
      ],
      Girls: [
        {
          name: "Ella Parsons",
          amount: 781,
          dates: ["Class of 2022"],
          image: parsons,
        },
      ],
    },
  },
  Assists: {
    set: {
      Boys: [
        {
          name: "Kyle Senkerik",
          amount: 15.0,
          dates: ["4/30/2024 vs Independence (3 Sets)"],
          image: senkerik,
        },
      ],
      Girls: [
        {
          name: "Ava Pritz",
          amount: 12.6,
          dates: ["9/14/2021 vs Cienega (5 Sets)"],
          image: pritz,
        },
      ],
    },
    match: {
      Boys: [
        {
          name: "Kyle Senkerik",
          amount: 64,
          dates: ["3/21/2024 vs Cienega"],
          image: senkerik,
        },
      ],
      Girls: [
        {
          name: "Ava Pritz",
          amount: 63,
          dates: ["9/14/2021 vs Cienega"],
          image: pritz,
        },
      ],
    },
    season: {
      Boys: [
        {
          name: "Kyle Senkerik",
          amount: 947,
          dates: ["2024"],
          image: senkerik,
        },
      ],
      Girls: [
        {
          name: "Ava Pritz",
          amount: 931,
          dates: ["2021"],
          image: pritz,
        },
      ],
    },
    career: {
      Boys: [
        {
          name: "Kyle Senkerik",
          amount: 1313,
          dates: ["Class of 2025"],
          image: senkerik,
        },
      ],
      Girls: [
        {
          name: "Ava Pritz",
          amount: 1104,
          dates: ["Class of 2022"],
          image: pritz,
        },
      ],
    },
  },
};
