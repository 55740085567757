import firebase from "firebase";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCzZQoc-zU-003_rSR5DlEYC65bhwQbHBQ",
  authDomain: "wvb-bracket.firebaseapp.com",
  projectId: "wvb-bracket",
  storageBucket: "wvb-bracket.appspot.com",
  messagingSenderId: "766103906408",
  appId: "1:766103906408:web:caefc6eb8af995411ab3dd",
  measurementId: "G-CS24VQH63V",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export {
  db
};
