import "../App.css";
import "./Teams.css";

import CaptionImage from "../modules/CaptionImage";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import { ProgramList } from "../schedule/ProgramList";
import { TeamList } from "./TeamList";
import { useState } from "react";

function Teams() {
  const seasons = [
    { label: "Boys", value: "Boys" },
    { label: "Girls", value: "Girls" },
  ];
  const [season, setSeason] = useState("Girls");
  const years = [
    ...new Set(
      TeamList.map((t) => {
        return t.year;
      })
    ),
  ].map((y) => {
    return { label: y, value: y };
  });
  const [year, setYear] = useState(2024);
  const handleSeasonChange = (event) => {
    setSeason(event.target.value);
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  return (
    <div>
      <Header active={"Teams"} />
      <div className="content">
        <Dropdown
          label="Season:"
          options={seasons}
          value={season}
          onChange={handleSeasonChange}
          key={season}
        />
        <Dropdown
          label="Year:"
          options={years}
          value={year}
          onChange={handleYearChange}
          key={year}
        />
        <div>
          {ProgramList.find((p) => p.year == year && p.season === season) ? (
            <CaptionImage
              src={
                ProgramList.find((p) => p.year == year && p.season === season)
                  .img
              }
            />
          ) : null}
          {TeamList.filter((t) => t.year == year && t.season === season).map(
            (t) => {
              return (
                <div className="teamBox">
                  <h3>{t.level}</h3>
                  <h5>
                    Head Coach: {t.coaches[0]}
                    {t.coaches.length > 1 ? (
                      <>
                        <br />
                        Assistant Coach{t.coaches.length > 2 ? "es" : ""}:{" "}
                        {t.coaches.map((c, index) => {
                          return index == 0 ? (
                            <></>
                          ) : index > 1 ? (
                            <>, {c}</>
                          ) : (
                            <>{c}</>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </h5>
                  {t.img ? <CaptionImage src={t.img} /> : null}
                  {t.roster ? (
                    <table>
                      {t.roster
                        .sort((a, b) => a.number - b.number)
                        .map((p) => {
                          return (
                            <tr>
                              <td>{p.number}</td>
                              <td>{p.name}</td>
                              <td>{p.class}</td>
                            </tr>
                          );
                        })}
                    </table>
                  ) : (
                    <h5>Coming Soon!</h5>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

const Dropdown = ({ label, value, options, onChange }) => {
  return (
    <div className="dropdown">
      <label>{label}</label>
      <div className="select">
        <select value={value} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Teams;
