import { useState } from "react";
import "../App.css";

function Matchup({match, index, currentMatchups, setCurrentMatchups, matches, setMatches, updateRankings}) {
    const [score11, setScore11] = useState(0)
    const [score12, setScore12] = useState(0)
    const [score13, setScore13] = useState(0)
    const [score21, setScore21] = useState(0)
    const [score22, setScore22] = useState(0)
    const [score23, setScore23] = useState(0)

    //match = {t1:{scores:[], roster:[]},t2:{scores:[], roster:[]}}
    const saveMatch = () => {
        let setsWon1 = 0
        let setsWon2 = 0
        if(score11 > score21){
            setsWon1+=1
        }else{
            setsWon2+=1
        }
        if(score12 > score22){
            setsWon1+=1
        }else{
            setsWon2+=1
        }
        if(score13 + score23 != 0){
            if(score13 > score23){
                setsWon1+=1
            }else{
                setsWon2+=1
            }
        }
        const newMatches = [...matches, {
            t1:{
                scores:[score11,score12,score13],
                setsWon:setsWon1,
                roster:match.t1.roster},
            t2:{
                scores:[score21,score22,score23],
                setsWon:setsWon2,
                roster:match.t2.roster}
        }]
        setMatches([...newMatches])
        updateRankings({theseMatches: [{
            t1:{
                scores:[score11,score12,score13],
                setsWon:setsWon1,
                roster:match.t1.roster},
            t2:{
                scores:[score21,score22,score23],
                setsWon:setsWon2,
                roster:match.t2.roster},
        }]})
        let newMatchups = [...currentMatchups]
        newMatchups.splice(index,1)
        setCurrentMatchups(newMatchups)
    }

  return (
    <div style={{display:"flex", alignItems:"center"}}>
        <button onClick={() => {
            let tmpMatchups = [...currentMatchups]
            for(let i=0;i<currentMatchups.length;i++){
                if(match.t1.roster[0].id == currentMatchups[i].t1.roster[0].id){
                    tmpMatchups.splice(i,1)
                    break
                }
            }
            setCurrentMatchups([...tmpMatchups])
        }}>
            Cancel
        </button>
        <table style={{marginRight:"8px"}}>
            <thead>
                <tr>
                    <td></td>
                    <td>S1</td>
                    <td>S2</td>
                    <td>S3</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                    {match.t1.roster.map((player,index)=>{
                        if(index == match.t1.roster.length-1){
                            return player.name
                        }else{
                            return player.name + ", "
                        }
                    })}
                    </td>
                    <td><input value={score11} onChange={e => setScore11(e.target.value-0)}/></td>
                    <td><input value={score12} onChange={e => setScore12(e.target.value-0)}/></td>
                    <td><input value={score13} onChange={e => setScore13(e.target.value-0)}/></td>
                </tr>
                <tr>
                    <td>
                    {match.t2.roster.map((player,index)=>{
                        if(index == match.t2.roster.length-1){
                            return player.name
                        }else{
                            return player.name + ", "
                        }
                    })}
                    </td>
                    <td><input value={score21} onChange={e => setScore21(e.target.value-0)}/></td>
                    <td><input value={score22} onChange={e => setScore22(e.target.value-0)}/></td>
                    <td><input value={score23} onChange={e => setScore23(e.target.value-0)}/></td>
                </tr>
            </tbody>
        </table>
        <button disabled={score11+score12<30 && score21+score22<30 && score23<11 && score13<11} onClick={() => saveMatch()}>
            Save
        </button>
    </div>
  );
}

export default Matchup;
